<template>
  <div class="">
    <ProjectForm
        title="Create Project"
        action="Create"
        :formLoader="loading"
        @submit="createProject"
    />
  </div>
</template>

<script>
import ProjectForm from "@/views/components/projects/ProjectForm";
import ProjectService from "@/services/ProjectService";

export default {
  name: "Create",
  components: {ProjectForm},
  data() {
    return {
      projectService: new ProjectService(),
      loading: false
    }
  },
  methods: {
    createProject(data) {
      this.loading = true
      this.projectService.create(data)
          .then(res => {
            console.log(res)
            this.$router.push({name: 'all-projects'})
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>
